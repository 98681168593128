<template>
  <div class="publisher-checkbox">
    <div @click="isExpend = !isExpend" class="publisher">
      <div class="left">
        <a-checkbox @click.stop="clickPublisherCheckBox" :checked="checkedAll" :indeterminate="indeterminate" :disabled="disabled"></a-checkbox>
        <span class="name" style="margin-left: 8px;" :title="publisherInfo.publisherName">{{ publisherInfo.publisherName }}</span>
      </div>
      <!-- <a-icon :class="{'is-expend': isExpend, 'icon': true}" type="down" /> -->
      <Arrow :class="{'is-expend': isExpend, 'icon': true}"/>
    </div>
    <div class="place-list" v-if="isExpend">
      <PlaceCheckBox
        :last="index === placeList.length - 1"
        :currentCheckedIdList="currentCheckedIdList"
        @checkedPlace="checkedPlace"
        :checkedIdList="checkedIdList"
        v-for="(place, index) in placeList"
        :key="place.placeId"
        :placeInfo="place"/>
    </div>
  </div>
</template>

<script>
import PlaceCheckBox from './PlaceCheckBox'
import Arrow from '@/assets/icons/arrow.svg?inline'
export default {
  name: 'PublisherCheckBox',
  components: { PlaceCheckBox, Arrow },
  data () {
    return {
      isExpend: false
    }
  },
  props: {
    publisherInfo: {
      default: () => ({}),
      type: Object,
      required: true
    },
    checkedIdList: {
      default: () => ([]),
      type: Array,
      required: true
    },
    currentCheckedIdList: {
      default: () => ([]),
      type: Array,
      required: true
    }
  },
  computed: {
    placeList () {
      if (!this.publisherInfo || !this.publisherInfo.childList) {
        return []
      }
      return this.publisherInfo.childList || []
    },
    // 禁用
    disabled () {
      const ids = this.placeList.map((place) => place.placeId)
      return ids.every((id) => this.checkedIdList.includes(id))
    },
    // 全选
    checkedAll () {
      const ids = this.placeList.map((palce) => palce.placeId)
      return ids.every((id) => this.currentCheckedIdList.includes(id))
    },
    // 部分选中
    indeterminate () {
      const ids = this.placeList.map((place) => place.placeId)
      return ids.some((id) => this.currentCheckedIdList.includes(id)) && !ids.every((id) => this.currentCheckedIdList.includes(id))
    }
  },
  methods: {
    checkedPlace (placeId) {
      console.log(placeId)
      if (this.currentCheckedIdList.includes(placeId)) {
        console.log(1)
        this.checkedPlaceForPublisher(this.currentCheckedIdList.filter((id) => id !== placeId))
      } else {
        console.log(2)
        this.checkedPlaceForPublisher([...this.currentCheckedIdList, placeId])
      }
    },
    checkedPlaceForPublisher (idList) {
      this.$emit('checkedPlaceForPublisher', idList)
    },
    clickPublisherCheckBox () {
      if (this.disabled) {
        return
      }
      if (this.checkedAll) {
        // 已全选
        // 过滤currentCheckedIdlist扔出这个账号以外的且不在checkedIdList中的dspId
        // 需要过滤掉的id数组
        const nocheckedIdList = this.placeList.map((place) => place.placeId).filter((id) => !this.checkedIdList.includes(id))
        // 过滤后的id数组
        const currentCheckedIdList = this.currentCheckedIdList.filter((id) => !nocheckedIdList.includes(id))
        // 抛出
        this.checkedPlaceForPublisher(currentCheckedIdList)
      } else {
        // 未全选
        // 选中当前账户下所有未选中的广告源
        const placeIdList = this.placeList.map((place) => place.placeId)
        // 合并已选id数组后去重
        const currentCheckedIdList = [...new Set([...this.currentCheckedIdList, ...placeIdList])]
        // 抛出
        this.checkedPlaceForPublisher(currentCheckedIdList)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.publisher-checkbox {
  user-select: none;
  padding-top: 10px;
  padding-bottom: 10px;
  .publisher{
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-content: center;
    color: @defaultFontColor !important;
    .icon{
      // width: 12px;
      // height: 12px;
      transition: transform 0.3s;
      &.is-expend{
        transform: rotate(180deg);
      }
    }
    .left{
      display: flex;
      flex-wrap: nowrap;
      .name{
        display: inline-block;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .place-list{
    width: 100%;
    box-sizing: border-box;
    position: relative;
    padding-top: 10px;
  }
}
</style>
