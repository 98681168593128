<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-24 14:50:56
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-11 15:24:56
 * @FilePath: /mediatom-web/src/viewsForManage/Flow/AdplaceConfig/LeftMain/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="left-main">
    <div class="left-head">
      <a-input @change="handleChangeName" v-model.trim="query.name" placeholder="请输入供应商名称/ID" class="search-item"></a-input>
    </div>
    <a-button @click="handleAddSource" :disabled="btnDisabled" type="primary" class="left-btn">添加到配置列表</a-button>
    <div class="publisher-list">
      <div class="publisher-item" @click.prevent="handleCheckedPublisher(publisher)" v-for="publisher in dataList" :key="publisher.publisherId" :title="publisher.publisherName">
        <a-checkbox :disabled="checkedIdList.includes(publisher.publisherId)" :checked="currentCheckedIdList.includes(publisher.publisherId)">{{ publisher.publisherName }}</a-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import { getPnblisherAndPlaceTreeList } from '@/apiForManage/budget/config'
export default {
  name: 'LeftMain',
  data () {
    return {
      query: {
        name: undefined,
        dspId: undefined
      },
      dataList: [],
      currentCheckedIdList: [],
      timer: null
    }
  },
  props: {
    checkedIdList: {
      type: Array,
      default: () => ([])
    }
  },
  watch: {
    checkedIdList: {
      handler (newVal, oldVal) {
        if (oldVal && oldVal.every((item) => newVal.includes(item)) && newVal.length === oldVal.length) {
          return
        }
        this.currentCheckedIdList = [...newVal]
      },
      deep: true,
      immediate: true
    }
  },
  created () {
    this.query.dspId = this.$route.query.id
    this.getPublisherList()
  },
  computed: {
    btnDisabled () {
      return this.currentCheckedIdList.length === this.checkedIdList.length
    }
  },
  methods: {
    handleChangeName () {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.getPublisherList()
        this.timer = null
      }, 500)
    },
    handleAddSource () {
      // 将后续勾选的id列表抛出
      this.$emit('addSource', this.currentCheckedIdList.filter((id) => !this.checkedIdList.includes(id)))
    },
    handleCheckedPublisher (item) {
      const id = item.publisherId
      if (this.currentCheckedIdList.includes(id)) {
        this.currentCheckedIdList = this.currentCheckedIdList.filter((item) => id !== item)
      } else {
        this.currentCheckedIdList.push(id)
      }
    },
    async getPublisherList () {
      const { data = {} } = await getPnblisherAndPlaceTreeList({ type: '1', ...this.query })
      const { items = [] } = data
      this.dataList = items
    }
  }
}
</script>

<style lang="less" scoped>
.left-main {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .left-head{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    border-bottom: 1px solid @compBorderColor;
    padding-bottom: 11px;
    .search-item{
      width: 100%;
    }
  }
  .left-btn{
    margin: 20px 0 14px 0;
  }
  .publisher-list{
    display: flex;
    flex-direction: column;
    position: relative;
    .publisher-item{
      cursor: pointer;
      padding: 10px 2px;
    }
  }
}
</style>
