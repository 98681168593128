<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-06-14 12:13:03
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-07-19 16:45:55
 * @FilePath: /mediatom-web/src/viewsForManage/Budget/BudgetSource/components/CheckSearchModal/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-modal
    v-model="checkSearchVisible"
    title="自定义筛选框"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    width="508px"
    dialogClass="check_modal"
  >
    <div class="wrapper">
      <div class="left">
        <div class="check-item" v-for="item in query" :key="item.prop">
          <a-checkbox :disabled="item.disabled" :checked="item.checked" @click="handleCheckedItem(item)">{{ item.name }}</a-checkbox>
        </div>
      </div>
      <div class="right">
        <div class="right-head">
          已选字段（{{ checkList.length }}）
        </div>
        <div class="checked-list">
          <div class="checked-item" :class="{disabled: item.disabled}" v-for="item in checkList" :key="item.prop">
            <div class="name">{{ item.name }}</div>
            <a-icon class="icon" @click="handleCloseItem(item)" type="close" />
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { saveSearchItem } from '@/apiForManage/user'
export default {
  data () {
    return {
      query: {}
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    searchMap: {
      default: () => ({}),
      type: Object
    }
  },
  watch: {
    visible: {
      handler (val) {
        if (val) {
          this.query = JSON.parse(JSON.stringify(this.searchMap))
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    checkList () {
      return Object.values(this.query).filter((item) => item.checked)
    },
    checkSearchVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    handleCheckedItem (item) {
      if (item.disabled) {
        return
      }
      item.checked = !item.checked
    },
    handleCloseItem (item) {
      if (item.disabled) {
        return
      }
      for (const key in this.query) {
        if (this.query[key].prop === item.prop) {
          this.query[key].checked = false
        }
      }
    },
    handleCancel () {
      this.checkSearchVisible = false
    },
    async handleSubmit () {
      const selectInfoList = []
      for (const key in this.query) {
        if (Object.hasOwnProperty.call(this.query, key) && this.query[key].checked) {
          selectInfoList.push(this.query[key].prop)
        }
      }
      const resp = await saveSearchItem({
        selectInfoList,
        menuKey: 'dspSource'
      })
      if (resp.code !== 200) {
        return
      }
      this.$emit('handleChangeChecked', this.query)
      this.checkSearchVisible = false
      this.$message.success('操作成功')
    }
  }
}
</script>

<style lang="less">
.check_modal .ant-modal-body {
  padding: 0;
}
</style>

<style lang="less" scoped>
.wrapper{
  max-height: 350px;
  display: flex;
  justify-content: flex-start;
  .left{
    padding: 20px 20px;
    width: 324px;
    border-right: 1px solid rgba(21,34,50,0.12);
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow-y: auto;
    .check-item{
      width: 50%;
      height: 40px;
      display: flex;
      align-items: center;
      // justify-content: center;
      padding-left: 30px;
    }
  }
  .right{
    flex: 1 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px;
    .right-head{
      width: 100%;
      padding-bottom: 10px;
      font-weight: 500;
    }
    .checked-list{
      height: 0;
      flex: 1 auto;
      overflow-y: auto;
      .checked-item{
        display: flex;
        justify-content: space-between;
        padding:0 0 10px 0;
        align-items: center;
        color: @compValueColor;
        &.disabled{
          color: @compDisabledColor;
        }
          .name{
          font-size: 13px;
        }
        .icon{
          cursor: pointer;
        }
      }
    }
  }
}
</style>
