<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-23 18:54:11
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-05 15:06:03
 * @FilePath: /mediatom-web/src/viewsForManage/Flow/AdplaceConfig/Header.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="place-config-head">
    <div class="left">
      <a-select class="place-config-head-select" v-model="compValue">
        <a-select-option value="1">配置到供应商</a-select-option>
        <a-select-option value="2" v-if="!+sourceType">配置到广告位</a-select-option>
      </a-select>
      <span class="place-config-head-title" :title="platName" >广告平台：{{ getText(platName) }}</span>
      <span class="place-config-head-title" :title="platAccountName">所属账号：{{ getText(platAccountName) }}</span>
      <span class="place-config-head-title" >分成比例：{{ settleRatio }}%</span>
      <span class="place-config-head-title" :title="dspName" >广告源名称：{{ getText(dspName) }}</span>
      <span class="place-config-head-title" :title="platPlaceId" >ID：{{ getText(platPlaceId) }}<CopySvg @click="copyId(platPlaceId)" title="复制" class="svg"/></span>
    </div>
    <span class="primary-text" @click="handleSkipToAdx">查看数据详情</span>
  </div>
</template>

<script>
import CopySvg from '@/assets/icons/copy.svg?inline'
import { copy } from '@/utils/string'
export default {
  components: { CopySvg },
  data () {
    return {
      platName: '',
      platAccountName: '',
      dspName: '',
      dspId: undefined,
      settleRatio: undefined,
      platPlaceId: undefined,
      // 0 自有  1 mergeX
      sourceType: undefined
    }
  },
  computed: {
    compValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      }
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  created () {
    const { query = {} } = this.$route
    const { platName, platAccountName, dspName, id, settleRatio, platPlaceId, sourceType } = query
    this.platName = platName
    this.platAccountName = platAccountName
    this.dspName = dspName
    this.dspId = id
    this.settleRatio = settleRatio
    this.platPlaceId = platPlaceId
    this.sourceType = sourceType
  },
  methods: {
    // 复制方法
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    getText (text, len = 10) {
      return text.length > len ? `${text.slice(0, len)}...` : text
    },
    handleSkipToAdx () {
      const url = this.$router.resolve({
        name: 'synthreport',
        query: {
          dspId: this.dspId,
          hasPlat: 1,
          hasSupplier: 1,
          hasPlace: 1
        }
      })
      window.open(url.href, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .place-config-head-select{
  .ant-select-selection{
    background-color: @primary-color;
    .ant-select-selection-selected-value, .ant-select-arrow-icon{
      color: #fff;
    }
  }
}
.place-config-head{
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #5a607f;
  .left{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .place-config-head-select{
      margin-right: @compMargin;
      width: 150px;
    }
    .place-config-head-title{
      margin-right: 41px;
      position: relative;
      &::after{
        content: '';
        position: absolute;
        right: -20px;
        height: 20px;
        width: 1px;
        background-color: #ecedef;
      }
      &:last-child{
        &::after{
          display: none;
        }
      }
      .svg{
        cursor: pointer;
        margin-left: 6px;
      }
    }
  }
  .primary-text{
    cursor: pointer;
  }
}
</style>
