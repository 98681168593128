<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-10-22 10:54:12
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-10-29 16:20:31
 * @FilePath: /mediatom-web/src/viewsForManage/Budget/BudgetSource/components/sourceModal/AppReportedForm/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="app-reported-container">
    <!-- 多应用上报 -->
    <a-form-model-item label="多应用上报" prop="multiApplicationReport">
      <a-radio-group v-model.trim="formquery.multiApplicationReport" @change="handleChangeMultiApplicationReport">
        <a-radio :value="1"> 开启 </a-radio>
        <a-radio :value="0"> 关闭 </a-radio>
      </a-radio-group>
      <a-button
        icon="plus"
        type="link"
        v-if="formquery.multiApplicationReport"
        @click="handleAddReportedList"
      >增加应用</a-button
      >
    </a-form-model-item>
    <div v-for="(item, index) in formquery.multiApplicationReportVoList" :key="index">
      <a-form-model-item v-if="formquery.multiApplicationReport" :label="`应用${index + 1}`">
        <a-button type="link" icon="close" @click="handleRemoveReportedList(index)"></a-button>
      </a-form-model-item>
      <a-form-model-item
        :rules="[{ required: !!formquery.multiApplicationReport, message: `请输入上报名称`, trigger: 'blur' }]"
        label="上报名称"
        :prop="`multiApplicationReportVoList[${index}].appnameReplace`"
      >
        <a-input placeholder="请输入上报名称" v-model.trim="item.appnameReplace"></a-input>
      </a-form-model-item>
      <a-form-model-item
        :rules="[{ required: !!formquery.multiApplicationReport, message: `请输入上报版本号`, trigger: 'blur' }]"
        label="上报版本号"
        :prop="`multiApplicationReportVoList[${index}].versionReplace`"
      >
        <a-input placeholder="请输入上报版本号" v-model.trim="item.versionReplace"></a-input>
      </a-form-model-item>
      <a-form-model-item
        :rules="[{ required: !!formquery.multiApplicationReport, message: `请输入上报包名`, trigger: 'blur' }]"
        label="上报包名"
        :prop="`multiApplicationReportVoList[${index}].bundleReplace`"
      >
        <a-input placeholder="请输入上报包名" v-model.trim="item.bundleReplace"></a-input>
      </a-form-model-item>
      <a-form-model-item
        v-if="formquery.multiApplicationReport"
        :rules="[{ required: true, message: `请输入权重`, trigger: 'blur' }]"
        label="权重"
        :prop="`multiApplicationReportVoList[${index}].weight`"
      >
        <a-input type="number" placeholder="请输入权重" v-model.trim.number="item.weight">
          <span slot="suffix">%</span>
        </a-input>
      </a-form-model-item>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppReportedForm',
  data () {
    return {}
  },
  created () {
    this.$set(this.formquery, 'multiApplicationReport', this.value.multiApplicationReport || 0)
    this.$set(this.formquery, 'multiApplicationReportVoList', this.value.multiApplicationReportVoList || [])
    if (!this.formquery.multiApplicationReportVoList.length) {
      this.handleAddReportedList()
    }
  },
  computed: {
    formquery: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    appnameReplaceList: {
      type: Array,
      default: () => []
    },
    versionReplaceList: {
      type: Array,
      default: () => []
    },
    bundleReplaceList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleChangeMultiApplicationReport (e) {
      this.formquery.multiApplicationReportVoList = []
      this.handleAddReportedList()
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    handleAddReportedList () {
      this.formquery.multiApplicationReportVoList.push({
        appnameReplace: undefined,
        versionReplace: undefined,
        bundleReplace: undefined,
        weight: undefined
      })
    },
    handleRemoveReportedList (index) {
      this.formquery.multiApplicationReportVoList.splice(index, 1)
    },
    validateWeight () {
      if (
        !this.formquery.multiApplicationReport ||
        (this.formquery.multiApplicationReport && this.formquery.multiApplicationReportVoList.length === 0)
      ) {
        return true
      }
      let sum = 0
      this.formquery.multiApplicationReportVoList.forEach((item) => {
        sum += +item.weight
      })
      if (sum !== 100) {
        this.$message.error('应用上报权重之和必须为100')
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style lang="less" scoped>
// .app-reported-container {
//   border: 1px solid;
// }
</style>
