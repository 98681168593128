/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-16 15:20:56
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-04-25 12:24:07
 * @FilePath: /apiForManage/advertisement.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
// 新增定向信息
export function directionalSetting (data) {
  return request({
    url: `/adPlan/directionalSetting`,
    method: 'post',
    data
  })
}

// 获取广告联盟pager数据
export function getPlatPager (data) {
  return request({
    url: `plat/pager`,
    method: 'post',
    data
  })
}
// 联盟广告修改广告平台
export function updatePlat (data) {
  return request({
    url: `/plat/update`,
    method: 'post',
    data
  })
}

// 联盟广告添加广告平台
export function addPlat (data) {
  return request({
    url: `/plat/add`,
    method: 'post',
    data
  })
}
// 修改素材替换设置
export function updateCreativeReplaceSetting (data) {
  return request({
    url: `/place/updateCreativeReplaceSetting`,
    method: 'post',
    data
  })
}
// 查询素材替换设置
export function getCreativeReplaceSetting (data) {
  return request({
    url: `/place/getCreativeReplaceSetting`,
    method: 'post',
    data
  })
}
