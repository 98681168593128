<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-28 15:17:29
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-05-20 16:39:20
 * @FilePath: /mediatom-web/src/viewsForManage/Budget/DspSourceConfig/PlaceLeftMain/PlaceCheckBox.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="place-checkbox" :class="{last}" :title="placeInfo.placeName">
    <a-checkbox :disabled="disabled" @click.stop="$emit('checkedPlace', placeInfo.placeId)" :checked="checked">{{ placeInfo.placeName }}</a-checkbox>
  </div>
</template>

<script>
export default {
  name: 'PlaceCheckBox',
  computed: {
    checked () {
      return this.currentCheckedIdList.includes(this.placeInfo.placeId)
    },
    disabled () {
      return this.checkedIdList.includes(this.placeInfo.placeId)
    }
  },
  props: {
    placeInfo: {
      default: () => ({}),
      type: Object
    },
    checkedIdList: {
      default: () => ([]),
      type: Array
    },
    currentCheckedIdList: {
      default: () => ([]),
      type: Array
    },
    last: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style lang="less" scoped>
.place-checkbox {
  width: 100%;
  box-sizing: border-box;
  padding-left: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  &.last {
    padding-bottom: 0;
  }
}
::v-deep{
  .ant-checkbox-wrapper{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    span:last-child{
      max-width: 240px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
