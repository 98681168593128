<template>
  <a-modal
    v-model="frequencyVisible"
    dialogClass="frequency_modal"
    title="频次控制"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    :width="700"
  >
    <div class="frequency_wrapper">
      <a-form-model ref="ruleForm" :model="formquery" :rules="rules" v-bind="layout">
        <!-- 广告平台名称 -->
        <a-form-model-item label="广告平台">
          {{ platName }}
        </a-form-model-item>
        <!-- 广告源ID -->
        <a-form-model-item label="广告源">
          {{ sourceInfo.name }}
        </a-form-model-item>
        <FrequencyFormItems v-model="formquery"/>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import {
  frequencyControl
} from '@/api/aggregate'
import FrequencyFormItems from '@/viewsForManage/Flow/components/FrequencyFormItems'
export default {
  components: { FrequencyFormItems },
  data () {
    return {
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 }
      },
      formquery: {
        requestFreq: undefined,
        requestInterval: undefined,
        impressFreq: undefined,
        clickFreq: undefined,
        impressFreqDay: undefined,
        impressFreqHour: undefined,
        clickInterval: undefined,
        impressInterval: undefined,
        deviceImpressFreqDay: undefined,
        deviceClickFreqDay: undefined
        // requestRatio: 100
      },
      rules: {
      }
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    sourceInfo: {
      default: () => ({}),
      type: Object
    },
    query: {
      default: () => ({}),
      type: Object
    },
    platList: {
      default: () => ([]),
      type: Array
    }
  },
  watch: {
    sourceInfo: {
      handler (val) {
        this.formquery = JSON.parse(JSON.stringify(val))
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    frequencyVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    platName () {
      return this.platList.find((item) => +item.id === +this.sourceInfo.platId)?.name
    }
  },
  methods: {
    handleCancel () {
      this.frequencyVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let url = ''
          let query = {}
            url = frequencyControl
            query = {
              ...this.formquery,
              id: +this.formquery.id,
              requestFreq: this.formquery.requestFreq,
              deviceRequestFreq: this.formquery.deviceRequestFreq,
              deviceRequestInterval: this.formquery.deviceRequestInterval,
              impressFreqDay: this.formquery.impressFreqDay,
              clickFreqDay: this.formquery.clickFreqDay
            }
          const resp = await url(query)
          if (resp.code === 200) {
            this.$message.success('修改成功!')
            this.$emit('changeSource')
            this.frequencyVisible = false
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less">
.frequency_modal .ant-modal-body {
  .frequency_wrapper {
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
  }
}
</style>
