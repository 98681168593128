/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-07-19 15:27:38
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-07-19 15:30:17
 * @FilePath: /mediatom-web/src/apiForManage/user.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
const api = {
  saveSearchItem: 'user/updateSelectInfo',
  getSearchItem: 'user/getSelectInfo'
}
export default api

// 保存搜索项
export function saveSearchItem (data) {
  return request({
    url: api.saveSearchItem,
    method: 'post',
    data
  })
}

// 查询当前账号设置的搜索项
export function getSearchItem (data) {
  return request({
    url: api.getSearchItem,
    method: 'post',
    data
  })
}
