<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-23 17:56:04
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-05-11 14:54:31
 * @FilePath: /mediatom-web/src/layouts/ConfigLayout.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="config-wrapper">
    <div class="head">
      <slot name="head"></slot>
    </div>
    <div class="main">
      <div class="main-left">
        <span class="title">{{ title1 }}</span>
        <div class="inner">
          <slot name="left"></slot>
        </div>
      </div>
      <div class="main-right">
        <span class="title">{{ title2 }}</span>
        <div class="inner">
          <slot name="right"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title1: {
      default: '',
      type: String
    },
    title2: {
      default: '',
      type: String
    }
  }
}
</script>

<style lang="less" scoped>
@footHeight: 82px;
.config-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  .head,
  .main .main-left .inner,
  .main .main-right .inner {
    border-radius: @mediumRadius;
    box-shadow: @modelShadow;
    border: 1px solid @modelBorderColor;
    background-color: #fff;
  }
  .head {
    // padding: @smallMargin;
    margin-bottom: @mediumMargin;
  }
  .main {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    position: relative;
    .main-left,
    .main-right {
      display: flex;
      flex-direction: column;
    }
    // 左侧宽度
    @leftWidth: 318px;
    .main-left {
      min-width: @leftWidth;
      width: @leftWidth;
      margin-right: @mediumMargin;
    }
    .main-right {
      width: 0;
      flex: 1;
      position: relative;
      min-height: 500px;
      .inner {
        position: relative;
      }
    }
    @titleHight: 20px;
    .main-left .inner,
    .main-right .inner {
      height: calc(100% - @titleHight);
      padding: @smallMargin;
    }
    .main-left .title,
    .main-right .title {
      line-height: @titleHight;
      margin-bottom: 12px;
      margin-left: 10px;
      font-weight: 500;
      color: @defaultFontColor;
    }
  }
}
</style>
