/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-23 19:23:57
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-04-28 16:53:08
 * @FilePath: /mediatom-web/src/apiForManage/flow/config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
const flowApi = {
  getBindDspLink: '/rtbAd/bindInfo',
  getPublisherAndPlaceList: '/shield/placeList',
  bindPublisherAndPlace: '/rtbAd/batchAdd'
}

/**
 * 获取绑定广告源列表
 * @export
 * @param {object} data
 * @return {Promise}
 * */
export function getBindDspLink (data) {
  return request({
    url: flowApi.getBindDspLink,
    method: 'post',
    data
  })
}

/**
 * 获取供应商广告位树形列表
 * @export
 * @param {object} data
 * @return {Promise}
 */
export function getPnblisherAndPlaceTreeList (data) {
  return request({
    url: flowApi.getPublisherAndPlaceList,
    method: 'post',
    data
  })
}

/**
 * 绑定供应商广告位（单个、批量）
 * @export
 * @param {object} data
 * @return {Promise}
 * */
export function bindPublisherAndPlace (data) {
  return request({
    url: flowApi.bindPublisherAndPlace,
    method: 'post',
    data
  })
}
